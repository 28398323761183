.pipeline-logo {
  transition: 0.1s all linear;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  height: 30px;
  margin: auto;
  margin-top: 10px;
  display: block;

  &.login-logo {
    &.size-small {
      height: 20px;
    }
    &.size-large {
      height: 60px;
    }
    &.size-x-large {
      height: 80px;
    }
  }

}

html.dark body .pipeline-logo.light,
html:not(.light):not(.dark) body .pipeline-logo.dark,
html.light body .pipeline-logo.dark {
  display: none !important;
}