app-home-launcher {

    ion-content {
        
        ion-grid {
            ion-row {
                ion-col {
                    padding: 2px;
                    
                    ion-card {
                        --background: transparent;
                        background: transparent;
                        
                        ion-card-content {
                            background: rgb(3,165,218);
                            --background: linear-gradient(140deg, rgba(22,65,148,1) 0%, rgba(22,65,148,1) 55%, rgba(32,90,201,1) 99%); 
                            background: linear-gradient(140deg, rgba(22,65,148,1) 0%, rgba(22,65,148,1) 55%, rgba(32,90,201,1) 99%); 
                            border-radius: 10px;
                            --border-radius: 10px;
                            padding-top: 0px !important;
                            width: 100% !important;
                            height: 100px !important;
                            margin: auto;
                            margin-top: 2px;
                            display: block;

                            ion-icon {
                                position: relative;
                                color: var(--ion-color-white) !important;
                                top: 0px;

                                @media screen and (min-width: 769px) {
                                    --font-size: 50px !important;
                                    font-size: 50px !important;
                                }
                            }

                            @media screen and (max-width: 768px) {
                                width: 100% !important;
                                height: 33vw !important;
                                min-height: 33vw !important;
                                max-height: 33vw !important;
                            }

                        }

                        &:not(.usePhotoAsBackground) {
                            ion-card-content {

                                ion-thumbnail {
                                    position: relative;
                                    width: 90px !important;
                                    height: 90px !important;
                                    border-radius: 90px;
                                    --border-radius: 90px;
                                    
                                    @media screen and (max-width: 768px) {
                                        width: 33vw !important;
                                        height: 33vw !important;
                                    }

                                    @media screen and (min-width: 769px) {
                                        top: 4px !important;
                                    }

                                }
                                
                            }
                        }

                        ion-item {
                            --background: transparent;
                            background: transparent;

                            ion-label {
                                h6 {
                                    @media screen and (min-width: 769px) {
                                        --font-size: 14px !important;
                                        font-size: 14px !important;
                                    }
                                }
                            }
                        }

                    }
                }
            }
        }
    }

}

html.dark {
    app-home-launcher {
        ion-content {
            background: rgb(0,0,0);
            --background: linear-gradient(0deg, rgba(45,45,45,1) 0%, rgba(0,0,0,1) 99%) !important;
            background: linear-gradient(0deg, rgba(45,45,45,1) 0%, rgba(0,0,0,1) 99%) !important;
        }
    }
}

html.light {
    app-home-launcher {
        ion-content {
            background: rgb(212,212,212);
            --background: linear-gradient(0deg, rgba(235,235,235,1) 0%, rgba(255,255,255,1) 99%) !important;
            background: linear-gradient(0deg, rgba(235,235,235,1) 0%, rgba(255,255,255,1) 99%) !important;
        }
    }
}