:root {
  --pipeline-forgot-password-web-left-area-background-color: #164194;
  --pipeline-header-background-color: #fff;
  --pipeline-login-web-left-area-background-color: #164194;
  --pipeline-post-reader-background-color: #fff;
  --pipeline-register-web-left-area-background-color: #164194;
  --pipeline-sidebar-background-color: #fff;
  --pipeline-sidebar-background-color-contrast: #164194;

  --ion-card-background: #fff;
  --ion-item-background: #fff;
  --ion-toolbar-background: #fff;
  --ion-background-color: #f9f9f9;
  
  --ion-color-primary: #164194;
  --ion-color-primary-rgb: 22, 65, 148;
  --ion-color-primary-contrast: #fff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #0b204b;
  --ion-color-primary-tint: #1c54c2;
  --ion-color-secondary: #FFFF00;
  --ion-color-secondary-rgb: 225, 225, 0;
  --ion-color-secondary-contrast: #000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #c8c805;
  --ion-color-secondary-tint: #fbfb71;
  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;
  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;
  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;
  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;
  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;
  /** medium **/
  --ion-color-medium: #d1d2d5;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;
  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-color-white: #ffffff;
  --ion-color-white-rgb: 255, 255, 255;
  --ion-color-white-contrast: #000000;
  --ion-color-white-contrast-rgb: 0, 0, 0;
  --ion-color-white-shade: #808289;
  --ion-color-white-tint: #9d9fa6;

  --ion-color-facebook: #3b5998;
  --ion-color-facebook-rgb: 59, 89, 152;
  --ion-color-facebook-contrast: #ffffff;
  --ion-color-facebook-contrast-rgb: 255, 255, 255;
  --ion-color-facebook-shade: #304778;
  --ion-color-facebook-tint: #4b70be;

  --ion-color-black: #151515;
  --ion-color-black-rgb: 15, 15, 15;
  --ion-color-black-contrast: #ffffff;
  --ion-color-black-contrast-rgb: 255, 255, 255;
  --ion-color-black-shade: #000000;
  --ion-color-black-tint: #444444;
}

.ion-color-facebook {
  --ion-color-base: #3b5998;
  --ion-color-base-rgb: 59, 89, 152;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255, 255, 255;
  --ion-color-shade: #304778;
  --ion-color-tint: #4b70be;
}

.ion-color-white {
  --ion-color-base: #ffffff;
  --ion-color-base-rgb: 255, 255, 255;
  --ion-color-contrast: #000000;
  --ion-color-contrast-rgb: 0, 0, 0;
  --ion-color-shade: #ddd;
  --ion-color-tint: #fff;
}

.ion-color-black {
  --ion-color-base: #151515;
  --ion-color-base-rgb: 15, 15, 15;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255, 255, 255;
  --ion-color-shade: #000000;
  --ion-color-tint: #444444;
}

.ion-color-subblack {
  --ion-color-base: #353535;
  --ion-color-base-rgb: 15, 15, 15;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255, 255, 255;
  --ion-color-shade: #000000;
  --ion-color-tint: #444444;
}

img.inverted {
  filter: invert(100%);
}

.light,
.light body,
.light.ios,
.light.ios body,
.light.md,
.light.md body {
  --ion-background-color: #fff !important;
  --ion-card-background: #fff !important;
  --ion-item-background: #fff !important;
  --ion-toolbar-background: #fff !important;
}

.light.light_pipeline,
.light.light_pipeline body,
.light.light_pipeline.md,
.light.light_pipeline.ios,
.light.light_pipeline.md body,
.light.light_pipeline.ios body {
  --ion-background-color: #fff !important;
  --ion-color-base: #e2d8ff !important;
  --ion-color-light: #e2d8ff !important;
  --ion-card-background: #e2d8ff !important;
  --ion-item-background: #e2d8ff !important;
  --ion-toolbar-background: #e2d8ff !important;
}

@import "./src/theme/components/img/logo";

html:not(.light) {
  @media (prefers-color-scheme: dark) {

    img.inverted {
      filter: invert(0%) !important;
    }
    
    ion-content.ion-color-dark .post-page a,
    ion-content.ion-color-primary .post-page a {
      color: #000;
    }
  
    /*
   * Dark Colors
   * -------------------------------------------
   */
    body {
        --pipeline-sidebar-background-color: #000;
        --pipeline-sidebar-background-color-contrast: #fff;
              
        --ion-color-primary: #fff;
        --ion-color-primary-rgb: 255, 255, 255;
        --ion-color-primary-contrast: #000;
        --ion-color-primary-contrast-rgb: 0,0,0;
        --ion-color-primary-shade: #0b204b;
        --ion-color-primary-tint: #1c54c2;
        --ion-color-secondary: #FFFF00;
        --ion-color-secondary-rgb: 225, 225, 0;
        --ion-color-secondary-contrast: #000;
        --ion-color-secondary-contrast-rgb: 0, 0, 0;
        --ion-color-secondary-shade: #c8c805;
        --ion-color-secondary-tint: #fbfb71;
        --ion-color-tertiary: #6a64ff;
        --ion-color-tertiary-rgb: 106, 100, 255;
        --ion-color-tertiary-contrast: #ffffff;
        --ion-color-tertiary-contrast-rgb: 255, 255, 255;
        --ion-color-tertiary-shade: #5d58e0;
        --ion-color-tertiary-tint: #7974ff;
        --ion-color-success: #2fdf75;
        --ion-color-success-rgb: 47, 223, 117;
        --ion-color-success-contrast: #000000;
        --ion-color-success-contrast-rgb: 0, 0, 0;
        --ion-color-success-shade: #29c467;
        --ion-color-success-tint: #44e283;
        --ion-color-warning: #ffd534;
        --ion-color-warning-rgb: 255, 213, 52;
        --ion-color-warning-contrast: #000000;
        --ion-color-warning-contrast-rgb: 0, 0, 0;
        --ion-color-warning-shade: #e0bb2e;
        --ion-color-warning-tint: #ffd948;
        --ion-color-danger: #ff4961;
        --ion-color-danger-rgb: 255, 73, 97;
        --ion-color-danger-contrast: #ffffff;
        --ion-color-danger-contrast-rgb: 255, 255, 255;
        --ion-color-danger-shade: #e04055;
        --ion-color-danger-tint: #ff5b71;
        --ion-color-dark: #f4f5f8;
        --ion-color-dark-rgb: 244, 245, 248;
        --ion-color-dark-contrast: #000000;
        --ion-color-dark-contrast-rgb: 0, 0, 0;
        --ion-color-dark-shade: #d7d8da;
        --ion-color-dark-tint: #f5f6f9;
        --ion-color-medium: #313131;
        --ion-color-medium-rgb: 65, 65, 65;
        --ion-color-medium-contrast: #ffffff;
        --ion-color-medium-contrast-rgb: 255, 255, 255;
        --ion-color-medium-shade: #212121;
        --ion-color-medium-tint: #373737;
        --ion-color-light: #101113;
        --ion-color-light-rgb: 34, 36, 40;
        --ion-color-light-contrast: #ffffff;
        --ion-color-light-contrast-rgb: 255, 255, 255;
        --ion-color-light-shade: #1e2023;
        --ion-color-light-tint: #383a3e;
        --ion-color-white: #ffffff;
        --ion-color-white-rgb: 255, 255, 255;
        --ion-color-white-contrast: #000000;
        --ion-color-white-contrast-rgb: 0, 0, 0;
        --ion-color-white-shade: #808289;
        --ion-color-white-tint: #9d9fa6;
        --ion-color-facebook: #3b5998;
        --ion-color-facebook-rgb: 59, 89, 152;
        --ion-color-facebook-contrast: #ffffff;
        --ion-color-facebook-contrast-rgb: 255, 255, 255;
        --ion-color-facebook-shade: #304778;
        --ion-color-facebook-tint: #4b70be;
  
        --ion-color-black: #151515;
        --ion-color-black-rgb: 15, 15, 15;
        --ion-color-black-contrast: #ffffff;
        --ion-color-black-contrast-rgb: 255, 255, 255;
        --ion-color-black-shade: #000000;
        --ion-color-black-tint: #444444;
    }
    /*
   * iOS Dark Theme
   * -------------------------------------------
   */
    .ios body {
        --ion-background-color: #000000;
        --ion-background-color-rgb: 0, 0, 0;
        --ion-text-color: #ffffff;
        --ion-text-color-rgb: 255, 255, 255;
        --ion-color-step-50: #0d0d0d;
        --ion-color-step-100: #1a1a1a;
        --ion-color-step-150: #262626;
        --ion-color-step-200: #333333;
        --ion-color-step-250: #404040;
        --ion-color-step-300: #4d4d4d;
        --ion-color-step-350: #595959;
        --ion-color-step-400: #666666;
        --ion-color-step-450: #737373;
        --ion-color-step-500: #808080;
        --ion-color-step-550: #8c8c8c;
        --ion-color-step-600: #999999;
        --ion-color-step-650: #a6a6a6;
        --ion-color-step-700: #b3b3b3;
        --ion-color-step-750: #bfbfbf;
        --ion-color-step-800: #cccccc;
        --ion-color-step-850: #d9d9d9;
        --ion-color-step-900: #e6e6e6;
        --ion-color-step-950: #f2f2f2;
        --ion-toolbar-background: #000000;
        --ion-item-background: #1c1c1c;
        --ion-item-background-activated: #313131;
        --ion-card-background: #1c1c1c;
        --ion-item-background: #1c1c1c;
        --ion-toolbar-background: #1c1c1c !important;
    }
    /*
   * Material Design Dark Theme
   * -------------------------------------------
   */
    .md body {
        --ion-background-color: #121212;
        --ion-background-color-rgb: 18, 18, 18;
        --ion-text-color: #ffffff;
        --ion-text-color-rgb: 255, 255, 255;
        --ion-border-color: #222222;
        --ion-color-step-50: #1e1e1e;
        --ion-color-step-100: #2a2a2a;
        --ion-color-step-150: #363636;
        --ion-color-step-200: #414141;
        --ion-color-step-250: #4d4d4d;
        --ion-color-step-300: #595959;
        --ion-color-step-350: #656565;
        --ion-color-step-400: #717171;
        --ion-color-step-450: #7d7d7d;
        --ion-color-step-500: #898989;
        --ion-color-step-550: #949494;
        --ion-color-step-600: #a0a0a0;
        --ion-color-step-650: #acacac;
        --ion-color-step-700: #b8b8b8;
        --ion-color-step-750: #c4c4c4;
        --ion-color-step-800: #d0d0d0;
        --ion-color-step-850: #dbdbdb;
        --ion-color-step-900: #e7e7e7;
        --ion-color-step-950: #f3f3f3;
        --ion-item-background: #1A1B1E;
        --ion-card-background: #1A1B1E;
        --ion-item-background: #1A1B1E;
        --ion-toolbar-background: #1A1B1E !important;
    }
    ion-title.title-large {
        --color: white;
    }
  }
}

html.dark {

  img.inverted {
    filter: invert(0%) !important;
  }
  
  ion-content.ion-color-dark .post-page a,
  ion-content.ion-color-primary .post-page a {
    color: #000;
  }

  /*
 * Dark Colors
 * -------------------------------------------
 */
  body {
      --pipeline-sidebar-background-color: #000;
      --pipeline-sidebar-background-color-contrast: #fff;
      
      --ion-color-primary: #164194;
      --ion-color-primary-rgb: 22, 65, 148;
      --ion-color-primary-contrast: #fff;
      --ion-color-primary-contrast-rgb: 255, 255, 255;
      --ion-color-primary-shade: #0b204b;
      --ion-color-primary-tint: #1c54c2;
      --ion-color-secondary: #FFFF00;
      --ion-color-secondary-rgb: 225, 225, 0;
      --ion-color-secondary-contrast: #000;
      --ion-color-secondary-contrast-rgb: 0, 0, 0;
      --ion-color-secondary-shade: #c8c805;
      --ion-color-secondary-tint: #fbfb71;
      --ion-color-tertiary: #6a64ff;
      --ion-color-tertiary-rgb: 106, 100, 255;
      --ion-color-tertiary-contrast: #ffffff;
      --ion-color-tertiary-contrast-rgb: 255, 255, 255;
      --ion-color-tertiary-shade: #5d58e0;
      --ion-color-tertiary-tint: #7974ff;
      --ion-color-success: #2fdf75;
      --ion-color-success-rgb: 47, 223, 117;
      --ion-color-success-contrast: #000000;
      --ion-color-success-contrast-rgb: 0, 0, 0;
      --ion-color-success-shade: #29c467;
      --ion-color-success-tint: #44e283;
      --ion-color-warning: #ffd534;
      --ion-color-warning-rgb: 255, 213, 52;
      --ion-color-warning-contrast: #000000;
      --ion-color-warning-contrast-rgb: 0, 0, 0;
      --ion-color-warning-shade: #e0bb2e;
      --ion-color-warning-tint: #ffd948;
      --ion-color-danger: #ff4961;
      --ion-color-danger-rgb: 255, 73, 97;
      --ion-color-danger-contrast: #ffffff;
      --ion-color-danger-contrast-rgb: 255, 255, 255;
      --ion-color-danger-shade: #e04055;
      --ion-color-danger-tint: #ff5b71;
      --ion-color-dark: #f4f5f8;
      --ion-color-dark-rgb: 244, 245, 248;
      --ion-color-dark-contrast: #000000;
      --ion-color-dark-contrast-rgb: 0, 0, 0;
      --ion-color-dark-shade: #d7d8da;
      --ion-color-dark-tint: #f5f6f9;
      --ion-color-medium: #313131;
      --ion-color-medium-rgb: 65, 65, 65;
      --ion-color-medium-contrast: #ffffff;
      --ion-color-medium-contrast-rgb: 255, 255, 255;
      --ion-color-medium-shade: #212121;
      --ion-color-medium-tint: #373737;
      --ion-color-light: #101113;
      --ion-color-light-rgb: 34, 36, 40;
      --ion-color-light-contrast: #ffffff;
      --ion-color-light-contrast-rgb: 255, 255, 255;
      --ion-color-light-shade: #1e2023;
      --ion-color-light-tint: #383a3e;
      --ion-color-white: #ffffff;
      --ion-color-white-rgb: 255, 255, 255;
      --ion-color-white-contrast: #000000;
      --ion-color-white-contrast-rgb: 0, 0, 0;
      --ion-color-white-shade: #808289;
      --ion-color-white-tint: #9d9fa6;
      --ion-color-facebook: #3b5998;
      --ion-color-facebook-rgb: 59, 89, 152;
      --ion-color-facebook-contrast: #ffffff;
      --ion-color-facebook-contrast-rgb: 255, 255, 255;
      --ion-color-facebook-shade: #304778;
      --ion-color-facebook-tint: #4b70be;

      --ion-color-black: #151515;
      --ion-color-black-rgb: 15, 15, 15;
      --ion-color-black-contrast: #ffffff;
      --ion-color-black-contrast-rgb: 255, 255, 255;
      --ion-color-black-shade: #000000;
      --ion-color-black-tint: #444444;
  }
  /*
  
 * iOS Dark Theme
 * -------------------------------------------
 */
  &.ios body {
      --ion-background-color: #000000;
      --ion-background-color-rgb: 0, 0, 0;
      --ion-text-color: #ffffff;
      --ion-text-color-rgb: 255, 255, 255;
      --ion-color-step-50: #0d0d0d;
      --ion-color-step-100: #1a1a1a;
      --ion-color-step-150: #262626;
      --ion-color-step-200: #333333;
      --ion-color-step-250: #404040;
      --ion-color-step-300: #4d4d4d;
      --ion-color-step-350: #595959;
      --ion-color-step-400: #666666;
      --ion-color-step-450: #737373;
      --ion-color-step-500: #808080;
      --ion-color-step-550: #8c8c8c;
      --ion-color-step-600: #999999;
      --ion-color-step-650: #a6a6a6;
      --ion-color-step-700: #b3b3b3;
      --ion-color-step-750: #bfbfbf;
      --ion-color-step-800: #cccccc;
      --ion-color-step-850: #d9d9d9;
      --ion-color-step-900: #e6e6e6;
      --ion-color-step-950: #f2f2f2;
      --ion-toolbar-background: #000000 !important;
      --ion-item-background: #1c1c1c;
      --ion-item-background-activated: #313131;
      --ion-card-background: #1A1B1E !important;
      --ion-item-background: #1A1B1E !important;
  }
  /*
 * Material Design Dark Theme
 * -------------------------------------------
 */
  &.md body {
      --ion-background-color: #121212;
      --ion-background-color-rgb: 18, 18, 18;
      --ion-text-color: #ffffff;
      --ion-text-color-rgb: 255, 255, 255;
      --ion-border-color: #222222;
      --ion-color-step-50: #1e1e1e;
      --ion-color-step-100: #2a2a2a;
      --ion-color-step-150: #363636;
      --ion-color-step-200: #414141;
      --ion-color-step-250: #4d4d4d;
      --ion-color-step-300: #595959;
      --ion-color-step-350: #656565;
      --ion-color-step-400: #717171;
      --ion-color-step-450: #7d7d7d;
      --ion-color-step-500: #898989;
      --ion-color-step-550: #949494;
      --ion-color-step-600: #a0a0a0;
      --ion-color-step-650: #acacac;
      --ion-color-step-700: #b8b8b8;
      --ion-color-step-750: #c4c4c4;
      --ion-color-step-800: #d0d0d0;
      --ion-color-step-850: #dbdbdb;
      --ion-color-step-900: #e7e7e7;
      --ion-color-step-950: #f3f3f3;
      --ion-item-background: #1A1B1E;
      --ion-card-background: #1A1B1E;
      --ion-item-background: #1A1B1E;
      --ion-toolbar-background: #1A1B1E !important;
  }
  ion-title.title-large {
      --color: white;
  }

  &.dark_pipeline,
  &.dark_pipeline.md,
  &.dark_pipeline.ios,
  &.dark_pipeline body,
  &.dark_pipeline.md body,
  &.dark_pipeline.ios body {
    --ion-background-color: #000 !important;
    --ion-color-base: #13003f !important;
    --ion-color-light: #13003f !important;
    --ion-item-background: #13003f !important;
    --ion-card-background: #13003f !important;
    --ion-toolbar-background: #13003f !important;
  }

}